import React  from "react"
import { graphql, Link } from "gatsby"
import Swiper from "react-id-swiper"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import ProductTile from "../../components/ProductTile"
import Image from "../../components/Image"

import styles from "./ss21.module.scss"

const SS21 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  // const [swiper, getSwiper] = useState(null)

  const swiperParams = {
    // getSwiper: getSwiper,
    containerClass: `${styles.swiperContainer} swiper-container`,
    WrapperEl: "ol",
    // wrapperClass: `${styles.swiperWrapper} swiper-wrapper`,
    threshold: 10,
    // watchOverflow: true,
    // preloadImage: true,
    // updateOnImagesReady: true,
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: `${styles.swiperPagination} .swiper-pagination`,
      clickable: true,
    },
    allowTouchMove: false,
  }

  return (
    <div className={styles.ss21}>
      <SEO
        title="Spring Summer '21"
        description="New Collection"
        url={location.pathname}
      />

      <div className={styles.module}>
        <Swiper {...swiperParams}>
          <li
            key={1}
            className={styles.swiperSlide}
          >
            <Image
              image={{
                desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Carousel_1_1320x.jpg?v=1614808872",
                mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Carousel_1_850x.jpg?v=1614808872",
              }}
              altText="Spring Summer 21 Carousel Image"
            />
          </li>
          {/*<li*/}
          {/*  key={2}*/}
          {/*  className={styles.swiperSlide}*/}
          {/*>*/}
          {/*  <Image*/}
          {/*    image={{*/}
          {/*      desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Carousel_2_1320x.jpg?v=1615226232",*/}
          {/*      mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Carousel_2_850x.jpg?v=1615226232",*/}
          {/*    }}*/}
          {/*    altText="Spring Summer 21 Carousel Image"*/}
          {/*  />*/}
          {/*</li>*/}
        </Swiper>
        {/*<button*/}
        {/*  className={`${styles.swiperButtonPrev} swiper-navigation-prev`}*/}
        {/*  onClick={() => swiper.slidePrev()}*/}
        {/*/>*/}
        {/*<button*/}
        {/*  className={`${styles.swiperButtonNext} swiper-navigation-next`}*/}
        {/*  onClick={() => swiper.slideNext()}*/}
        {/*/>*/}
      </div>

      <header className={styles.header}>
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          Spring Summer '21
        </Heading>
        {/*<Filters*/}
        {/*  allItems={collectionStyles}*/}
        {/*  items={filteredStyles}*/}
        {/*  setItems={setFilteredStyles}*/}
        {/*  setFilteredColor={setFilteredColor}*/}
        {/*  collectionTitle={productCollection.title}*/}
        {/*/>*/}
      </header>

      <StoryGrid
        storyName="Pillow Top"
        storyHandle="pillow-top-ss21"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Pillowtop.jpg?v=1614808872"
        showName={true}
        nodes={[
          "solace-nappa-sandals",
          "solstice-nappa-sandals",
          // "absolute-nappa-sandals",
          "console-nappa-sandals",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="SS21"
      />

      <StoryGrid
        storyName="Opanca"
        storyHandle="opanca"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Opanca.jpg?v=1614808872"
        showName={true}
        nodes={[
          "all-clear-vitello-sandals",
          "red-alert-40-vitello-sandals",
          "red-alert-100-vitello-sandals",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="SS21"
      />

      {/*<StoryGrid*/}
      {/*  storyName="Rope"*/}
      {/*  storyHandle="rope"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Rope.jpg?v=1614808872"*/}
      {/*  nodes={[*/}
      {/*    "guiding-light-nappa-sandals",*/}
      {/*    "intuition-vitello-sandals",*/}
      {/*    "north-star-nappa-sandals",*/}
      {/*    "astra-suede-flats",*/}
      {/*    "figure-eight-vitello-sandals",*/}
      {/*  ].map(handle => nodes.find(style => style.handle === handle))}*/}
      {/*/>*/}

      <StoryGrid
        storyName="Natural Elements"
        storyHandle="natural-elements"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Natural_87df0b68-5f67-4745-b644-9721332b3b04.jpg?v=1615107985"
        showName={true}
        nodes={[
          "grass-roots-suede-sandals",
          "salinas-nappa-sandals",
          "serra-nappa-sandals",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="SS21"
      />

      <StoryGrid
        storyName="Ankle Wraps"
        storyHandle="ankle-wraps"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Airtime.jpg?v=1629757862"
        showName={true}
        nodes={[
          "air-time-vitello-sandals",
          "stares-nappa-sandals",
          // "free-rein-vitello-sandals",
          "crystal-clear-vitello-sandals",
          "clear-cut-vitello-sandals",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="SS21"
      />

      <StoryGrid
        storyName="Netting"
        storyHandle="netting"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Netting.jpg?v=1614808872"
        showName={true}
        nodes={[
          "next-step-fabric-flats",
          "new-world-pumps",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="SS21"
      />

      <StoryGrid
        storyName="Patent"
        storyHandle="patent"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Patent.jpg?v=1614808872"
        showName={true}
        nodes={[
          "beau-monde-patent-sandals",
          "setter-patent-flats",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="SS21"
      />

      <StoryGrid
        storyName="Stones"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/2_Stone.jpg?v=1620847945"
        nodes={[
          "rock-solid-suede-sandals",
          "stone-cold-suede-sandals",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="SS21"
      />

      <StoryGrid
        storyName="Graphic"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/4_Sex.jpg?v=1620847944"
        nodes={[
          "telegraphic-metallic-nappa-sandals",
          "sex-on-the-beach-metallic-nappa-sandals",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="SS21"
      />

      <StoryGrid
        storyName="TM x D'Ascoli"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/Collection_Dascoli.jpg?v=1629763302"
        nodes={[
          "utopia-vitello-sandals",
          "oasis-vitello-sandals",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="SS21"
      />
    </div>
  )
}

export default SS21

export const query = graphql`
    query SS21Query {
        allProductStyle(filter: {handle: {in: [
            "solstice-nappa-sandals",
            "console-nappa-sandals",
            "solace-nappa-sandals",
#            "absolute-nappa-sandals",
            "all-clear-vitello-sandals",
            "red-alert-40-vitello-sandals",
            "red-alert-100-vitello-sandals",
#            "guiding-light-nappa-sandals",
#            "astra-suede-flats",
#            "north-star-nappa-sandals",
#            "figure-eight-vitello-sandals",
#            "intuition-vitello-sandals",
            "grass-roots-suede-sandals",
            "salinas-nappa-sandals",
            "serra-nappa-sandals",
            "air-time-vitello-sandals",
            "stares-nappa-sandals",
#            "free-rein-vitello-sandals",
            "crystal-clear-vitello-sandals",
            "clear-cut-vitello-sandals",
            "next-step-fabric-flats",
            "new-world-pumps",
            "beau-monde-patent-sandals",
            "setter-patent-flats",
            "rock-solid-suede-sandals",
            "stone-cold-suede-sandals",
            "telegraphic-metallic-nappa-sandals",
            "sex-on-the-beach-metallic-nappa-sandals",
            "utopia-vitello-sandals",
            "oasis-vitello-sandals",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`

export const StoryGrid = ({ storyName, storyHandle, storyImage, showName, nodes, collection }) => {

  return (
    <ul className={styles.grid}>
      {storyImage &&
      <li className={styles.storyItem}>
        {storyHandle ?
          <Link to={`/collections/${storyHandle}?origin=collection-ss21`}>
            <Image
              image={{
                desktopImage: storyImage.replace(".jpg", "_1320x.jpg"),
                mobileImage: storyImage.replace(".jpg", "_850x.jpg"),
              }}
              altText={`${storyName} Story`}
            />
            <span>
              {showName &&
              <>
                {storyName}<br />
              </>
              }
              <u>SHOP NOW</u>
            </span>
          </Link>
          :
          <Image
            image={{
              desktopImage: storyImage.replace(".jpg", "_1320x.jpg"),
              mobileImage: storyImage.replace(".jpg", "_850x.jpg"),
            }}
            altText={`${storyName} Story`}
          />
        }
      </li>
      }
      {nodes.map((style, i) => (
        <ProductTile
          key={i}
          index={i}
          style={style}
          customStyles={styles}
          origin={`collection-ss21`}
          collection={collection}
        />
      ))}
    </ul>
  )
}
