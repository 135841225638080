import React  from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import { StoryGrid } from "./ss21"

import styles from "./ss21.module.scss"

const FW21 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  return (
    <div className={styles.fw21}>
      <SEO
        title="Fall Winter '21"
        description="New Collection"
        url={location.pathname}
      />

      <header className={styles.header}>
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          Fall Winter '21
        </Heading>
      </header>

      <StoryGrid
        storyName="Pillow Top"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/1_Collection_Pillowtop.jpg?v=1629404900"
        nodes={[
          "stellar-velvet-crystals-sandals",
          "console-velvet-sandals",
          "comet-velvet-pumps",
          "satellite-65-velvet-pumps",
          "comet-suede-pumps",
          "solitude-suede-sandals",
          "console-suede-sandals",
          "console-nappa-welt-sandals",
          "solar-65-velvet-sandals",
          "solar-100-velvet-sandals",
          "solar-100-nappa-sandals",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="FW21"
      />

      <StoryGrid
        storyName="Jewel Tone Velvet"
        storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/2_Collection_JewelToneVelvet.jpg?v=1629404900"
        nodes={[
          "setter-velvet-flats",
          "galaxy-velvet-boots",
          "eclipse-100-velvet-boots",
          "eclipse-75-velvet-boots",
          "eris-velvet-boots",
          "idol-suede-boots",
            "wild-one-90-nappa-boots",
            "wild-one-65-nappa-boots",
          "great-frontier-leather-boots",
          "fiction-patent-boots",
          // "gift-patent-pumps",
        ].filter(handle => nodes.find(style => style.handle === handle))
          .map(handle => nodes.find(style => style.handle === handle))}
        collection="FW21"
      />

      {/*<StoryGrid*/}
      {/*  storyName="Oil Slick"*/}
      {/*  storyHandle="oil-slick"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/7_Collection_OilSlick_1fa0deb1-ee83-42b4-a413-fddbe91e8dcd.jpg?v=1629749757"*/}
      {/*  nodes={[*/}
      {/*    "gift-patent-pumps",*/}
      {/*    "fiction-patent-boots",*/}
      {/*  ].map(handle => nodes.find(style => style.handle === handle))}*/}
      {/*/>*/}

        <StoryGrid
          storyName="Motocross"
          storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/4_Collection_Moto.jpg?v=1629404900"
          nodes={[
              "perfecto-vitello-boots",
              "buckle-up-nappa-boots",
              "fuel-vitello-boots",
              "stage-nappa-boots",
              "duvet-nappa-boots",
              "monday-morning-suede-boots",
            "hendrix-suede-boots",
            "joplin-suede-boots",
          ].filter(handle => nodes.find(style => style.handle === handle))
            .map(handle => nodes.find(style => style.handle === handle))}
          collection="FW21"
        />

      {/*<StoryGrid*/}
      {/*  storyName="Pull On"*/}
      {/*  storyHandle="pull-on"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/3_Collection_PullOn_c3645598-4ad7-45fb-ab21-c2dc00755874.jpg?v=1629749758"*/}
      {/*  nodes={[*/}
      {/*  ].map(handle => nodes.find(style => style.handle === handle))}*/}
      {/*/>*/}

      {/*<StoryGrid*/}
      {/*  storyName="Rubber Creeper"*/}
      {/*  storyHandle="rubber-creeper"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/5_Collection_RubberCreeper.jpg?v=1629404900"*/}
      {/*  nodes={[*/}
      {/*  ].map(handle => nodes.find(style => style.handle === handle))}*/}
      {/*/>*/}

      {/*<StoryGrid*/}
      {/*  storyName="Bohemian"*/}
      {/*  storyHandle="bohemian"*/}
      {/*  storyImage="https://cdn.shopify.com/s/files/1/1103/4464/files/6_Collection_Bohemian_e56eaf6a-6abf-4c72-8508-9383438303bb.jpg?v=1629749757"*/}
      {/*  nodes={[*/}
      {/*    "joplin-suede-boots",*/}
      {/*    "hendrix-suede-boots",*/}
      {/*  ].map(handle => nodes.find(style => style.handle === handle))}*/}
      {/*/>*/}
    </div>
  )
}

export default FW21

export const query = graphql`
    query FW21Query {
        allProductStyle(filter: {handle: {in: [
            "stellar-velvet-crystals-sandals",
            "console-velvet-sandals",
            "comet-velvet-pumps",
            "satellite-65-velvet-pumps",
            "console-suede-sandals",
            "solitude-suede-sandals",
            "solar-100-velvet-sandals",
            "solar-65-velvet-sandals",
            "comet-suede-pumps",
            "console-nappa-welt-sandals",
            "solar-100-nappa-sandals",
            "eclipse-100-velvet-boots",
            "eclipse-75-velvet-boots",
            "galaxy-velvet-boots",
            "eris-velvet-boots",
            "setter-velvet-flats",
            "wild-one-90-nappa-boots",
            "wild-one-65-nappa-boots",
            "great-frontier-leather-boots",
            "idol-suede-boots",
            "perfecto-vitello-boots",
            "buckle-up-nappa-boots",
            "fuel-vitello-boots",
            "stage-nappa-boots",
            "duvet-nappa-boots",
            "monday-morning-suede-boots",
            "joplin-suede-boots",
            "hendrix-suede-boots",
#            "gift-patent-pumps",
            "fiction-patent-boots",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
